import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import PublicLayout from 'layout/PublicLayout';

const TokenLog = Loadable(lazy(() => import('views/TokenLog')));

const PublicRoutes = {
  path: '/',
  element: <PublicLayout />,
  children: [
    {
      path: '/token-log',
      element: <TokenLog />
    }
  ]
};

export default PublicRoutes;
