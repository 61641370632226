import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, Container } from '@mui/material';
import AdminContainer from 'ui-component/AdminContainer';
import Header from './Header';
// import Footer from 'ui-component/Footer';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  width: undefined,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  marginLeft: '20px',
  marginRight: '20px',
  [theme.breakpoints.down('md')]: {
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    marginRight: '10px',
    padding: '16px'
  }
}));

// ==============================|| PUBLIC LAYOUT ||============================== //

const PublicLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          flex: 'none'
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>
      <Main theme={theme}>
        <AdminContainer>
          <Outlet />
        </AdminContainer>
      </Main>
      {/* <Box sx={{ flex: 'none' }}>
        <Footer />
      </Box> */}
    </Box>
  );
};

export default PublicLayout;
